/** @jsx jsx */
import {
  jsx,
  Box,
  Button,
  Container,
  Image,
  Flex,
  Styled,
  Text,
} from "theme-ui"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import Slider from "react-slick"
import { uniqueId, shuffle } from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import "../utils/slick.css"
import "../utils/slick-theme.css"

import {
  Hero,
  LinkExt,
  Overlay,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  Announcement,
  ColorBar,
  PrevArrow,
  NewsGrid,
  NextArrow,
  SectionHeading,
  QuickLinksHome,
} from "../components/elements"

const IndexPage = ({ data }) => {
  const settings = {
    adaptiveHeight: "true",
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }

  return (
    <Layout>
    
      <HelmetDatoCms seo={data.datoCmsKidsHome.seoMetaTags} />
      <Main>
        <Announcement />
        {/* Hero-SlideShow */}
        <Hero sx={{ height: "auto" }}>
          <Slider {...settings} sx={{ position: "relative", zIndex: 5 }}>
            {shuffle(data.datoCmsKidsHome.slideshow).map(
              ({ buttonText, slideContent, image, link }) => (
                <BackgroundImage
                  Tag="div"
                  fluid={image.fluid}
                  key={`${uniqueId("slide_id_")}`}
                  sx={{
                    backgroundSize: "cover",
                    px: ["2.5em"],
                  }}
                >
                  <Container
                    sx={{
                      position: "relative",
                      top: [-4, -3, 0],
                      py: ["12em", "16em", "20em"],
                    }}
                  >
                    <Box
                      sx={{
                        background: [
                          null,
                          `linear-gradient(to right, rgba(86, 45, 181, 0.8), rgba(25, 175, 201, 0.8))`,
                        ],
                        position: "absolute",
                        padding: [0, 4],
                        width: ["full", "full", "3/4", "60%"],
                        zIndex: 10,
                      }}
                    >
                      <Styled.h1 sx={{ fontSize: [4, 5, 6] }}>
                        {slideContent}
                      </Styled.h1>
                      {link && (
                        <Link to={link}>
                          <Button as="span" variant="inverted" mt={3}>
                            {buttonText}
                          </Button>
                        </Link>
                      )}
                    </Box>
                  </Container>
                  <Overlay sx={{ opacity: [0.85, 0] }} />
                </BackgroundImage>
              )
            )}
          </Slider>
          <ColorBar
            sx={{ position: "absolute", bottom: 0, left: 0, zIndex: 10 }}
          />
        </Hero>

        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>
            <Flex
              sx={{
                flexWrap: "wrap",
                mx: [-2, -2, -4],
                width: "full",
              }}
            >
              <Box sx={{ width: ["full"], px: [2, 2, 4] }}>
                <Styled.h6 as="p">
                  {data.datoCmsKidsHome.introContent}
                </Styled.h6>
              </Box>

              <Box
                sx={{
                  width: ["full", "1/2"],
                  px: [2, 2, 4],
                }}
              >
                <Img fluid={data.datoCmsKidsHome.introImage.fluid} />

                <Flex
                  flexWrap="nowrap"
                  my={4}
                  sx={{ justifyContent: "center" }}
                >
                  <Image
                    src="https://www.datocms-assets.com/23576/1687374292-bch_neurology-2023-2024.png"
                    alt="Best Childrens Hospitals - Neurology 2023-2024"
                    width={150}
                    style={{ objectFit: "contain" }}
                    mx={2}
                  />

                  <Image
                    src="https://www.datocms-assets.com/23576/1691526102-bch_urology-2023-2024.png"
                    alt="Best Childrens Hospitals - Urology 2023-2024"
                    width={160}
                    style={{ objectFit: "contain" }}
                    mx={2}
                  />
                  <Image
                    src="https://www.datocms-assets.com/23576/1696454672-ncqa-recognition.png"
                    alt="NCQA Recognized"
                    width={160}
                    mx={2}
                    style={{ objectFit: "contain" }}
                  />
                </Flex>
              </Box>

              <Box sx={{ width: ["full", "1/2"], px: [2, 2, 4] }}>
                <Text
                  dangerouslySetInnerHTML={{
                    __html:
                      data.datoCmsKidsHome.contentNode.childMarkdownRemark.html,
                  }}
                />

                <Flex flexWrap="wrap" mx={-2} width="100%">
                  <Box px={2} width={["100%", "100%", "50%"]}>
                    <Link to="/about-us#centers-of-excellence">
                      <Button as="span" bg="children.1">
                        Centers of Excellence
                      </Button>
                    </Link>
                  </Box>

                  <Box px={2} width={["100%", "100%", "50%"]}>
                    <LinkExt to="https://www.flipsnack.com/wvumedicine/wvu-medicine-children-s-annual-report-2023/full-view.html">
                      <Button as="span">View Our 2023 Report</Button>
                    </LinkExt>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Container>
        </Section>

        {/* QuickLinks */}
        <QuickLinksHome />

        {/* NewsEvents */}
        <Section id="newsevents">
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Text sx={{ width: ["full"], textAlign: "center" }}>
                <SectionHeading>News & Events</SectionHeading>
              </Text>
              <NewsGrid />

              <Box sx={{ pt: 4, textAlign: "right", width: ["full"] }}>
                <LinkExt href="https://wvumedicine.org/news-feed/?sec=childrens-hospital">
                  See More Stories <FontAwesomeIcon icon={faChevronRight} />
                </LinkExt>
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
          
  
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    datoCmsKidsHome {
      title
      slug
      introContent
      id
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      introImage {
        fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        tags
      }
      slideshow {
        buttonText
        id
        link
        slideContent
        image {
          fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`
